export const shuffleArray = (array) => {
    let newarr = array
    for (var i = newarr.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1))
        var temp = newarr[i]
        newarr[i] = newarr[j]
        newarr[j] = temp
    }
    return newarr
}
