<template>
  <section class="training">
    <h1 id="page-title">
      <router-link :to="{ name: 'StudentVocabulary' }">
        <BackSvg class="mr-3"/>
      </router-link>
      {{ $t('words_training') }}
    </h1>
    <div v-if="mode === 'cards'">
      <WordCardsLessonPart v-if="words && words.length"
                           view="student"
                           :cards="words"
                           @done="wordCardsDone"/>
    </div>
    <div v-else>
      <div class="text-center mt-5"
           v-if="words.length > 0 && currentWord && !finished">
        <h2 class="the-word text-uppercase">{{ currentWord.word }}</h2>
        <div v-if="!translate"
             class="text-underlined pointer see-translation text-muted"
             @click="translate = true">
          {{ $t('see_translation') }}
        </div>
        <div v-else class="see-translation">
          {{ currentWord.translation }}
        </div>
        <div class="mx-auto memory-buttons">
          <b-row class="mt-5">
            <b-col>
              <a class="btn-themed btn-white"
                 @click="notMemorized">
                {{ $t('not_memorized') }}
              </a>
            </b-col>
            <b-col>
              <a class="btn-themed btn-white"
                 @click="memorized">
                {{ $t('memorized') }}
              </a>
            </b-col>
          </b-row>
          <div class="mt-4">
            <progress class="w-100"
                      :value="currentIndex"
                      :max="words.length - 1"/>
          </div>
        </div>

      </div>
      <div v-if="finished" class="text-center">
        <div class="fire">🔥</div>
        <h2 class="the-word mt-4 text-uppercase">{{ $t('well_done') }}!</h2>
        <div class="text-muted">{{ $t('you_have_learned') }}:</div>
        <div class="learned-stat">{{ remembered.length }}</div>
        <a class="btn-themed px-4 mt-4" @click="done">{{ $t('done') }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import { VocabularyService } from '@/services/api.service'
import BackSvg from '@/assets/svg/back.svg'
import WordCardsLessonPart from "../../../parts/class/WordCardsLessonPart"

export default {
  components: {
    WordCardsLessonPart,
    BackSvg
  },
  data() {
    return {
      translate: false,
      mode: this.$route.query.mode ? this.$route.query.mode : "all",
      amount: this.$route.query.amount ? this.$route.query.amount : 20,
      learned: this.$route.query.learned ? this.$route.query.learned : "0",
      words: [],
      currentIndex: 0,
      remembered: [],
      finished: false,
    }
  },
  mounted() {
    this.getWords()
  },
  metaInfo() {
    return {
      title: this.$t('words_training'),
    }
  },
  watch: {
    currentIndex() {
      this.translate = false
    }
  },
  methods: {
    async getWords() {
      if(this.mode === 'cards') {
        let res = await VocabularyService.getStudentCards(this.mode, this.learned, this.$route.query.page ? this.$route.query.page : 1, this.amount);
        this.words = res.data.data.data.map(x => x.word_card)
      } else {
        let res = await VocabularyService.getStudentWords(this.mode, 0, 1);
        this.words = res.data.data.data
      }
    },
    memorized() {
      this.remembered.push(this.currentWord.id)
      this.raiseIndex()
    },
    notMemorized() {
      this.raiseIndex()
    },
    raiseIndex() {
      if(this.currentIndex > 0 && (this.currentIndex + 1) >= this.words.length) {
        this.finished = true
        this.record()
        return
      }
      this.currentIndex++
    },
    record() {
      if(this.remembered.length > 0) {
        VocabularyService.wordsLearnedByStudent({ words: this.remembered })
      }
    },
    slidemodechange() {
      this.done()
    },
    async wordCardsDone() {
      await VocabularyService.cardsLearnedByStudent({ cards: this.words.map(x => x.id) })
      this.done()
    },
    done() {
      this.$router.push({ name: 'StudentVocabulary' })
    },
  },
  computed: {
    currentWord() {
      return this.words[this.currentIndex] ? this.words[this.currentIndex] : null
    }
  }
}
</script>

<style scoped lang="scss">
.training {
  .the-word {
    font-size: 40px;
  }
  .see-translation {
    font-size: 16px;
  }
  .memory-buttons {
    max-width: 400px;
    .btn-themed {
      width: 100%;
    }
    progress {
      /*background-color: green;*/
      color: $text-themed;
      height: 7px;
      border-radius: 7px;
      &::-webkit-progress-value {
        background: $text-themed;
      }
      &::-moz-progress-bar {
        background: $text-themed;
      }
    }
  }
  .fire {
    font-size: 40px;
  }
  .learned-stat {
    font-size: 40px;
    font-weight: 600;
  }
}
</style>


